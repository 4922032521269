import type { Shadows } from '@mui/material';

export const shadowSm = '0px 1px 2px 0px rgba(0, 0, 0, 0.05)';
export const shadowBase = '0px 1px 2px -1px rgba(0, 0, 0, 0.10)';
export const shadowMd = '0px 1px 3px 0px rgba(0, 0, 0, 0.10)';
export const shadowLg =
  '0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)';
export const shadowXl =
  '0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)';
export const shadow2xl =
  '0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)';

export const shadows: Shadows = [
  'none',
  shadowSm,
  shadowBase,
  shadowMd,
  shadowLg,
  shadowXl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl,
  shadow2xl
];
