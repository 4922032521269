'use client';

import type { SnackbarCloseReason, SnackbarProps } from '@mui/material';
import { Box, IconButton, Snackbar, SnackbarContent } from '@mui/material';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import {
  AlertCircleIcon,
  CheckCircleIcon,
  CloseIcon,
  InfoIcon
} from '../icons';
import { fontStyles } from '../theme/fonts';

export type ToastContextType = {
  /**
   * Displays a Toast in the lower left corner of the screen.
   *
   * @param message The primary message of the Toast to be displayed.
   * @param props Additional props to be passed to the Snackbar content.
   */
  showToast: (
    message: ReactNode | string,
    props?: Partial<ExtendableToastProps>
  ) => void;

  /**
   * Hides the current displayed Toast.
   */
  hideToast: (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
};

export const ToastContext = createContext<ToastContextType>({
  showToast: function () {},
  hideToast: function () {}
});

export type ToastVariant = 'success' | 'error' | 'info';

export type ExtendableToastProps = Omit<
  SnackbarProps,
  'open' | 'onClose' | 'ref' | 'message' | 'children'
> & { description?: ReactNode | string | undefined; variant: ToastVariant };

function IconForVariant({ variant }: { variant?: ToastVariant }): ReactNode {
  // 1px nudge to offset the icon by its frame
  switch (variant) {
    case 'success':
      return (
        <Box pt={'1px'}>
          <CheckCircleIcon />
        </Box>
      );
    case 'error':
      return (
        <Box sx={(theme) => ({ color: theme.palette.error.main })} pt={'1px'}>
          <AlertCircleIcon />
        </Box>
      );
    default:
      return (
        <Box pt={'1px'}>
          <InfoIcon />
        </Box>
      );
  }
}

/**
 * Provider to unify Toast management throughout the application.
 *
 * @param props
 * @param props.children Child content for provider
 * @returns
 */
export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [subject, setSubject] = useState<ReactNode | string | undefined>();
  const [description, setDescription] = useState<
    ReactNode | string | undefined
  >();
  const [variant, setVariant] = useState<ToastVariant | undefined>();
  const [props, setProps] = useState<Partial<ExtendableToastProps> | undefined>(
    {}
  );

  const showToast = (
    subject: ReactNode | string,
    { description, variant, ...props }: Partial<ExtendableToastProps> = {}
  ) => {
    setSubject(subject);
    setProps(props);
    setDescription(description);
    setVariant(variant);
  };

  const hideToast = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') return;
    setSubject(undefined);
    setDescription(undefined);
    setProps(undefined);
    setVariant(undefined);
  };

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
      <IconForVariant variant={variant} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={(theme) => ({ ...theme.typography.body1 })}>{subject}</Box>
        {description && (
          <Box sx={{ ...fontStyles.textMiniNormal }}>{description}</Box>
        )}
      </Box>
    </Box>
  );

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {subject && (
        <Snackbar open={!!subject} onClose={hideToast} {...props}>
          <SnackbarContent
            variant={variant}
            message={content}
            action={
              <IconButton onClick={hideToast}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Snackbar>
      )}
    </ToastContext.Provider>
  );
};

export default function useToast() {
  return useContext(ToastContext);
}
