import { createTheme } from '@mui/material/styles';
import {
  grey,
  foamGreen,
  natomaGreen,
  natomaBlack,
  warning,
  error,
  blue,
  success
} from './colors';
import { fontStyles, interFontFamily } from './fonts';
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CheckboxIndeterminateIcon
} from '@/src/icons';
import { shadows, shadowSm } from './shadows';
import { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import type { LinkBaseProps } from '@mui/material';
// See these MUI docs for customizing colors
// https://mui.com/material-ui/customization/palette/

/** Type to help integrate with MUI */
type LinkBehaviorProps = Omit<LinkProps, 'href'> &
  LinkBaseProps & { href: LinkProps['href'] };

/** Integrate to NextLink */
const LinkBehavior = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  function LinkBehavior({ href, ...props }, ref) {
    // Check if the link is external
    const hrefString = href.toString();
    const isExternal = hrefString.startsWith('http');

    if (isExternal) {
      return <a ref={ref} href={hrefString} {...props} />;
    }

    return <NextLink ref={ref} href={href} {...props} />;
  }
);

const theme = createTheme({});
const finalTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'default',
        color: 'secondary'
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          let props = {};
          if (ownerState.variant === 'default') {
            if (ownerState.color === 'primary') {
              props = {
                backgroundColor: foamGreen[600],
                color: grey[900],
                border: 0,
                '&:hover': {
                  backgroundColor: foamGreen[500]
                },
                '&:disabled': {
                  color: grey[500],
                  backgroundColor: foamGreen[300]
                }
              };
            } else if (ownerState.color === 'secondary') {
              props = {
                color: grey[600],
                background: theme.palette.common.white,
                border: `1px solid ${grey[200]}`,
                '&:hover': {
                  color: grey[900],
                  backgroundColor: grey[50]
                },
                '&:disabled': {
                  color: grey[500],
                  backgroundColor: grey[100],
                  borderColor: grey[200]
                }
              };
            } else if (ownerState.color === 'error') {
              props = {
                color: error[600],
                background: error[25],
                border: `1px solid ${error[300]}`,
                '&:hover': {
                  color: theme.palette.common.white,
                  backgroundColor: error[600]
                },
                '&:disabled': {
                  color: error[400],
                  backgroundColor: error[25],
                  borderColor: error[400]
                }
              };
            }
          } else if (ownerState.variant === 'text') {
            if (ownerState.color === 'primary') {
              props = {
                color: foamGreen[800]
              };
            }
          }
          return {
            padding: theme.spacing(1.5, 2),
            gap: theme.spacing(1),
            boxShadow: 'none',
            textTransform: 'none',
            '&.Mui-disabled': {
              pointerEvents: 'unset',
              cursor: 'not-allowed'
            },
            ...props
          };
        },
        startIcon: {
          margin: 0
        },
        endIcon: {
          margin: 0
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'medium'
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          let props = {};
          if (ownerState.size === 'small') {
            props = {
              width: '32px',
              height: '32px',
              padding: theme.spacing(0.875)
            };
          } else if (ownerState.size === 'medium') {
            props = {
              width: '40px',
              height: '40px',
              padding: theme.spacing(1.5)
            };
          } else if (ownerState.size === 'large') {
            props = {
              width: '44px',
              height: '44px',
              padding: theme.spacing(1.5)
            };
          }

          return {
            border: `1px solid ${grey[200]}`,
            borderRadius: theme.spacing(1.5),
            color: grey[900],
            backgroundColor: theme.palette.common.white,
            boxSizing: 'border-box',
            '&:hover': {
              color: grey[900],
              backgroundColor: grey[50]
            },
            '&.Mui-focused': {
              color: grey[600],
              backgroundColor: grey[100]
            },
            '&.Mui-disabled': {
              color: grey[400],
              backgroundColor: grey[50],
              pointerEvents: 'unset',
              cursor: 'not-allowed'
            },
            '& .MuiTouchRipple-root .MuiTouchRipple-child': {
              borderRadius: theme.spacing(1.5)
            },
            ...props
          };
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          justifyContent: 'flex-start',
          '&.Mui-selected': {
            color: theme.palette.text.primary
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2, 3)
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: theme.spacing(60),
          borderRadius: theme.spacing(3)
        },
        root: {
          'aria-modal': true
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 0, 0, 0),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: theme.palette.grey[900],
          '&:hover': {
            color: theme.palette.grey[600]
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          '&.MuiCard-root': {
            borderRadius: theme.spacing(3),
            borderColor: theme.palette.grey[700]
          }
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        variant: 'rounded',
        animation: 'wave'
      },
      styleOverrides: {
        root: {
          backgroundColor: grey[100]
        }
      }
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 4000
      }
    },
    MuiSnackbarContent: {
      defaultProps: {
        variant: 'info'
      },
      styleOverrides: {
        root: {
          color: grey[800],
          borderRadius: theme.spacing(2),
          borderWidth: 1,
          borderStyle: 'solid',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
          variants: [
            {
              props: { variant: 'success' },
              style: {
                color: grey[900],
                borderColor: '#3C9DB3',
                background: `linear-gradient(97deg, ${theme.palette.common.white}, ${success[50]}), #FFF`
              }
            },
            {
              props: { variant: 'error' },
              style: {
                borderColor: error[500],
                background: `linear-gradient(97deg, ${theme.palette.common.white}, ${error[50]}), #FFF`
              }
            },
            {
              props: { variant: 'info' },
              style: {
                color: grey[900],
                borderColor: grey[400],
                background: `linear-gradient(97deg, ${theme.palette.common.white}, ${grey[50]}), #FFF`
              }
            }
          ]
        },
        action: {},
        message: {}
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[200]}`,
          borderRadius: 12
        }
      }
    },
    MuiTable: {
      variants: [
        {
          props: { className: 'clickable' },
          style: {
            '& .MuiTableBody-root': {
              cursor: 'pointer',
              '& .MuiTableRow-root': {
                '&:hover': {
                  backgroundColor: theme.palette.grey[50]
                }
              }
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column'
        }
      }
    },
    MuiTableRow: {
      variants: [
        {
          props: { className: 'clickable' },
          style: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.grey[50]
            }
          }
        },
        {
          props: { className: 'nonclickable' },
          style: {
            cursor: 'not-allowed',
            color: theme.palette.grey[400],
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '& .MuiTableCell-root': {
              color: theme.palette.grey[400]
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          borderBottom: `1px solid ${theme.palette.divider}`,
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: theme.palette.background.default
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[900]
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...fontStyles.textMiniMedium,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          /**
           * Moving forward, we are going to have to wrap all raw text in <span> or equivalent
           * This is what applies the ellipsis - it needs a wrapper to determine its width overflow
           */
          '& *': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          },
          flex: 1,
          fontWeight: 500,
          borderBottom: 0,
          minHeight: 60,
          padding: theme.spacing(2)
        },
        head: {
          color: theme.palette.grey[500],
          gap: theme.spacing(0.5)
        },
        body: {
          color: theme.palette.grey[900]
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: { display: 'none' }
        }
      },
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          '& .MuiTabs-fixed': {
            flex: 'unset',
            width: 'auto',
            '& > div': {
              backgroundColor: theme.palette.grey[100],
              borderRadius: '12px',
              padding: theme.spacing(0.5),
              gap: theme.spacing(0.5)
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          textTransform: 'none',
          color: theme.palette.grey[600],
          borderRadius: '8px',
          minWidth: 0,
          minHeight: 0,
          padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
          border: '1px solid transparent',
          ...fontStyles.textRegularMedium,
          '&:disabled': {
            pointerEvents: 'auto',
            color: theme.palette.grey[400],
            cursor: 'not-allowed'
          },
          '&.Mui-selected': {
            color: theme.palette.grey[900],
            border: `1px solid ${theme.palette.grey[200]}`,
            backgroundColor: theme.palette.background.default
          },
          '&:hover': {
            backgroundColor: grey[25]
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
          height: `calc(100vh - ${theme.spacing(2)})`,
          maxWidth: `calc(100vw - ${theme.spacing(32 + 6)})`, // 32 is the sidebar width, 6 is the padding, should pull sidebar width into a constant
          margin: theme.spacing(1),
          borderRadius: theme.spacing(2),
          [theme.breakpoints.down('lg')]: {
            maxWidth: `calc(100vw - ${theme.spacing(2)})`
          }
        }
      }
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          borderRadius: '200px',
          borderColor: theme.palette.grey[200],
          display: 'flex',
          alignItems: 'center',
          padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
          gap: theme.spacing(0.5),
          '&:hover': {
            backgroundColor: theme.palette.grey[100]
          }
        },
        label: {
          ...fontStyles.textMiniMedium,
          padding: 0
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      },
      styleOverrides: {
        root: {
          marginTop: theme.spacing(0.5)
        },
        list: {
          padding: 0
        },
        paper: {
          minWidth: theme.spacing(10.5),
          padding: theme.spacing(0.5)
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: theme.spacing(4.5),
          padding: theme.spacing(1, 1.5),
          borderRadius: theme.spacing(1.5),
          gap: theme.spacing(1),
          color: theme.palette.text.secondary,
          '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 1.5),
          borderRadius: theme.spacing(1.5),
          minWidth: 84,
          marginTop: theme.spacing(0.5)
        },
        paper: {
          border: `1px solid ${theme.palette.grey[200]}`
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...fontStyles.textMicroNormal,
          maxWidth: '350px',
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1.5),
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          color: grey[600],
          boxShadow: shadowSm,
          margin: 0,
          '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementBottom': {
              marginTop: '2px'
            },
            '&.MuiTooltip-tooltipPlacementTop': {
              marginBottom: '2px'
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
              marginRight: '2px'
            },
            '&.MuiTooltip-tooltipPlacementRight': {
              marginLeft: '2px'
            }
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          '& .MuiBreadcrumbs-separator': {
            color: theme.palette.grey[400],
            ...fontStyles.displayExtraSmallMedium
          },
          '& .MuiBreadcrumbs-li, a, div': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            maxWidth: '240px',
            '& h3': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[200]
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxUncheckedIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />
      },
      styleOverrides: {
        root: {
          height: theme.spacing(2),
          width: theme.spacing(2),
          borderRadius: theme.spacing(0.5),
          padding: 0,
          color: grey[200],
          '&.Mui-checked': {
            '& rect.checkbox-checked_svg__CheckboxBackground': {
              fill: blue[50]
            },
            color: blue[600]
          },
          '&.MuiCheckbox-indeterminate': {
            '& rect.checkbox-indeterminate_svg__CheckboxBackground': {
              fill: blue[50]
            },
            color: blue[600]
          },
          '&.Mui-disabled': {
            '& rect.checkbox-unchecked_svg__CheckboxBackground': {
              fill: grey[25]
            },
            color: grey[25],
            border: `1px solid ${grey[100]}`
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '& .MuiAlert-action': {
            paddingTop: '2px'
          }
        }
      }
    }
  },
  shape: { borderRadius: 12 },
  palette: {
    common: {
      white: theme.palette.common.white,
      black: theme.palette.common.black,
      natomaBlack
    },
    primary: {
      main: natomaGreen
    },
    secondary: {
      main: theme.palette.grey[500]
    },
    error: {
      main: error['500']
    },
    warning: {
      main: warning['500']
    },
    info: {
      main: theme.palette.grey[400]
    },
    grey,
    foamGreen,
    divider: theme.palette.grey[200]
  },
  typography: {
    fontFamily: interFontFamily,
    fontSize: 13,
    h1: fontStyles.displayLargeMedium,
    h2: fontStyles.displayRegularMedium,
    h3: fontStyles.displayExtraSmallMedium,
    h4: fontStyles.textLargeNormal,
    h5: fontStyles.textRegularSemibold,
    body1: fontStyles.textRegularMedium,
    body2: fontStyles.textMiniMedium,
    subtitle1: fontStyles.textMiniNormal,
    subtitle2: fontStyles.textMicroMedium,
    helper1: fontStyles.textNanoNormal
  },
  shadows
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    helper1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    helper1?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    helper1: true;
  }
}

export default finalTheme;
